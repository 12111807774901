












































import Base from '@/mixins/Base.vue';
import { IService, IServiceResponse } from '@/interfaces/service';

const component = Base.extend({
    data() {
        return {
            active: false,

            confirmation: '',
            service: undefined as (any | IService),
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.service.name;
        },
    },
    mounted() {
        this.active = true;
        this.getService();
    },
    methods: {
        getService(): void {
            let url = `services/${this.$route.params.id}`;
            if (this.$route.params.client_id) url += `/${this.$route.params.client_id}`;

            this.get<IServiceResponse>(url).then(({ data }) => {
                this.service = data.data;
            });
        },
        save(): void {
            if (this.validated) {
                let url = `services/${this.$route.params.id}`;
                if (this.$route.params.client_id) url += `/${this.$route.params.client_id}`;

                this.delete(url).then(() => {
                    this.$router.push({ name: 'services.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
